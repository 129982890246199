import React, { FC } from 'react'
import { graphql } from 'gatsby'

import 'twin.macro'
import { BlogList } from '../components/blog/BlogList'

import { HeroTemplate } from '../components/layout/HeroTemplate'
import { ContentContainer, H2, PageSection } from '../components/layout/Layout'
import { AnalyticsConversionLink } from '../components/elements'
import { ROUTES } from '../components/layout/Navigation'


const Blog: FC<{ data: any }> = ({ data }) => {
  const posts = data.allContentfulPost.edges
  return (
    <HeroTemplate 
      bottom={<BlogListCTA />} 
      hero={(
        <ContentContainer tw="py-0 lg:py-16">
          <BlogList posts={posts} />
        </ContentContainer>
      )} 
    />
  )
}

const BlogListCTA = () => (
  <PageSection tw="sm:py-24">
    <ContentContainer tw="lg:flex lg:items-center lg:justify-between">
      <div tw="max-w-xl">
        <H2>
          It's Time. Get Your Money Back Now.
        </H2>
        <p tw="mt-4 text-sm sm:text-xl leading-6 sm:leading-7">
        Remove the uncertainty and hassle by letting our solicitors do the heavy lifting for you.
        </p>
      </div>
      <div tw="mt-8 flex flex-col items-center lg:flex-shrink-0 lg:mt-0">
        <AnalyticsConversionLink to={ROUTES.start.case}>
          Get started now
        </AnalyticsConversionLink>

        <p tw="mt-2 text-xs sm:text-sm font-medium leading-7 text-gray-700">
          Digital Application. Takes under 5 minutes
        </p>
      </div>
    </ContentContainer>
  </PageSection>
)

export const query = graphql`
  query blogQuery {
    allContentfulPost(
      limit: 1000
      sort: { fields: [publishDate], order: DESC }
      filter: {node_locale: { eq: "en-US" }}
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
          tags {
            title
            id
            slug
          }
        }
      }
    }
  }
`


export default Blog

