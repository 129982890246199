import React, { FC } from 'react'
import tw, { styled, css } from 'twin.macro'

import { Link } from 'gatsby'

const Grid = tw.div`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 sm:gap-6`

const PostContainer = styled(Link)<{}>(() => [
  tw`flex flex-col items-center`,
  css`
    & > h2 {
      ${tw`border-b-2 border-transparent`}
    }
  `,
  css`
    &:hover {
      & > h2 {
        ${tw`text-pink-400 border-pink-400`}
      }
    }
  `
])

const BlogImage = tw.img`object-cover max-h-56 w-full`

const HeadingHero = tw.h2`text-lg sm:text-xl font-semibold text-center text-white`

const PostDate = tw.span`text-indigo-300 text-sm`

export const BlogList: FC<{ posts: any }> = ({ posts }) => {
  return []
    ? (
      <Grid>
        {posts.map((post, i) => <BlogListItem post={post} key={i} />)}
      </Grid>
    )
    : (
      <span>No content found</span>
    )
}

const BlogListItem = ({ post }) => (
  <PostContainer to={`/knowledge-hub/${post.node.slug}`}>
    {post.node.heroImage && <BlogImage tw="mb-4" src={post.node.heroImage.sizes.src} />}
    
    <PostDate>{post.node.publishDate}</PostDate>
    <HeadingHero tw="sm:mt-2">{post.node.title}</HeadingHero>

    {post.node.tags && <TagList tw="sm:mt-2" tags={post.node.tags} isLink={false}/>}
  </PostContainer>
)

const TagContainer = tw.div`flex flex-row justify-center items-center`

const Tag = tw.div`mr-2 last:mr-0 text-xs uppercase text-indigo-300 font-medium`

const TagList = ({ tags, ...rest }) => {
  return (
    <TagContainer {...rest}>
      {tags.map((tag, index) => <Tag key={index}>#{tag.title}</Tag>)}
    </TagContainer>
  )
}
